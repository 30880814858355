<template>
  <q-header elevated>
    <q-toolbar class="bg-white text-primary q-pa-none">
      <q-toolbar-title class="col-2" shrink>
        <slot name="toolbar-title" />
      </q-toolbar-title>

      <Search class="col q-mx-md" />

      <q-separator vertical class="q-my-md" />

      <slot name="indicators" />

      <q-separator vertical class="q-my-md" />

      <slot name="user-menu" />
    </q-toolbar>
  </q-header>
</template>

<script>
import Search from './search'

export default {
  name: 'app-header',
  components: { Search }
}
</script>

<style>
</style>
