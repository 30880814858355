<template>
  <div class="bg-white q-ma-md" v-if="notifications">
    <div>
      <div class="row q-px-md q-py-none">
        <div class="col col-6">
          <base-input-select :options="filters" v-model="activeFilter" placeholder="Filtre" dense />
        </div>
        <div class="col col-6 row items-center justify-end">
          {{ $t('notifications.delete_all_notifications') }}
          <q-btn flat dense size="sm" icon="delete_outline" @click.prevent="deleteAllNotification()" />
        </div>
      </div>

      <q-list class="q-py-sm" separator v-if="items">
        <q-item v-for="{id, label, message, to, created_at} in items" :key="id">
          <q-item-section>
            <q-item-label class="text-default">
              <router-link :to="to" v-if="to">
                <strong>{{message}} - {{label}}</strong>
              </router-link>
              <strong v-else>{{message}} - {{label}}</strong>
            </q-item-label>

            <q-item-label caption>
              <div class="row justify-end q-pa-sm">
                <div class="col col-auto">
                  {{created_at}}
                </div>
              </div>
            </q-item-label>
          </q-item-section>

          <q-separator vertical/>

          <q-item-section side top class="justify-center">
            <q-btn flat dense size="sm" icon="delete_outline" @click.prevent="deleteNotification(id)" />
          </q-item-section>
        </q-item>
      </q-list>
    </div>

    <!-- <div v-else v-html="$t('notifications.no_notifications')" /> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'NotificationsListPanel',

  computed: {
    ...mapGetters({
      notifications: 'notifications/getNotifications'
    }),

    items () {
      const getRouteName = type => {
        if (type === 'GroupeCible') type = 'Cible'
        else if (type === 'Cible') type = 'Sous-cible'
        else if (type === 'Commentaire') type = 'task'
        else if (type === 'Tache') type = 'task'

        else if (type === 'PeriodeProbatoire') return 'suivi-de-periode-probatoire-form'

        return `${type.toLowerCase()}-show`
      }

      return this.notifications && this.notifications.map(({ id, created_at, data: { label, message, lien_id, lien_type } }) => {
        let getLabel = label => label
        if (lien_type === 'GroupeCible') getLabel = label => this.$te(`cibles.types.${label}`) ? this.$t(`cibles.types.${label}`) : label
        else if (lien_type === 'Cible') getLabel = label => this.$te(`sousCibles.types.${label}`) ? this.$t(`sousCibles.types.${label}`) : label
        else if (lien_type === 'PeriodeProbatoire') getLabel = label => `relance bilan ${label.substring(6, 7)} mois non reçu`

        return {
          id,
          label: getLabel(label),
          message: this.$t(`notifications.types.${message}`),
          to: lien_type && lien_id ? { name: getRouteName(lien_type), params: { id: lien_id } } : null,
          created_at: this.$formatDate(created_at, 'À HH:mm le DD/MM/YYYY')
        }
      })
    },

    filters () {
      const types = this.$t('notifications.types')
      return Object.keys(types).map(key => ({ label: types[key], value: key }))
    }
  },

  data () {
    return {
      activeFilter: null
    }
  },

  watch: {
    activeFilter (newVal, oldVal) {
      this.fetchNotifications()
    }
  },

  mounted () {
    this.fetchNotifications()
  },

  methods: {
    fetchNotifications () {
      const params = { rowsPerPage: 0 }
      if (this.activeFilter) params.type = this.activeFilter.value

      this.$store.dispatch('notifications/fetchNotifications', params)
    },

    deleteNotification (id) {
      this.$store.dispatch('notifications/deleteNotification', id).then(() => {
        this.fetchNotifications()
      }).then(() => { this.$store.dispatch('config/fetchCounts') })
        .catch(this.$notifyError)
    },

    deleteAllNotification () {
      this.$confirm(this.$t('notifications.delete_all_notifications_confirm'), this.$t('form.delete')).onOk(() => {
        this.$store.dispatch('notifications/deleteAllNotification').then(() => {
          this.fetchNotifications()
        }).then(() => { this.$store.dispatch('config/fetchCounts') })
          .catch(this.$notifyError)
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
</style>
