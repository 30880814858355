<template>
  <q-layout view="LHh LpR fFf">
    <AppHeader @onIndicatorClick="showRightDrawerTab">
      <template #toolbar-title>
        <q-btn color="default" flat no-caps dense class="q-ma-sm"
          :label="!leftDrawerOpen ? 'Menu' : 'Fermer'"
          :icon="!leftDrawerOpen ? 'menu' : 'close'"
          @click="leftDrawerOpen = !leftDrawerOpen" />
      </template>

      <template #indicators>
        <Indicators @click="indicator => showRightDrawerTab(indicator)" />
      </template>

      <template #user-menu>
        <UserMenu />
      </template>
    </AppHeader>

    <q-drawer v-model="leftDrawerOpen" side="left" content-class="bg-white"
      elevated behavior="mobile" no-swipe-close>
      <q-scroll-area class="fit">
        <q-toolbar class="bg-grey-2 shadow-2">
          <q-toolbar-title class="row items-baseline">
            <q-img src="/statics/gan-small.png" style="height: 24px; max-width: 24px" />
            <span class="text-h5 text-uppercase q-pl-sm">{{$t('global.site-name')}}</span>
          </q-toolbar-title>
        </q-toolbar>

        <DrawerMenu :item="{ children: this.mainMenuItems }" />
      </q-scroll-area>
    </q-drawer>

    <RightDrawer v-model="rightDrawerOpen">
      <template slot="tabs">
        <q-tabs v-model="rightDrawerActiveTab"
          align="left" no-caps dense narrow-indicator
          class="bg-grey-2" active-color="grey-9" indicator-color="primary">
          <q-tab v-for="tab in tabs" v-bind:key="tab.name" v-bind="tab" class="text-light"/>
        </q-tabs>
      </template>

      <template slot="tab-panels">
        <q-tab-panels v-model="rightDrawerActiveTab" animated>
          <q-tab-panel v-for="panel in tabPanels" :key="panel.name" :name="panel.name" class="q-pa-none">
            <component :is="panel.component" v-bind="panel.data" />
          </q-tab-panel>
        </q-tab-panels>
      </template>
    </RightDrawer>

    <q-page-container>
      <router-view v-if="user"/>
    </q-page-container>
  </q-layout>
</template>

<script>
import { mapGetters } from 'vuex'
import { DrawerMenu } from '../components/menu'
import { AppHeader, Indicators, UserMenu } from '../components/header'
import { RightDrawer, NotificationsListPanel, TasksListPanel, FavoritesListPanel } from '../components/drawers'

export default {
  name: 'MyLayout',
  components: { DrawerMenu, AppHeader, Indicators, UserMenu, RightDrawer },
  data () {
    return {
      leftDrawerOpen: false,
      rightDrawerOpen: false,
      rightDrawerActiveTab: null,
      tabs: [
        { name: 'notifications', label: 'Notifications' },
        { name: 'taches', label: 'Tâches' },
        { name: 'favoris', label: 'Favoris' }
      ]
    }
  },

  computed: {
    ...mapGetters({
      user: 'auth/getCurrentUser',
      mainMenuItems: 'config/getMainMenuItems'
    }),

    tabPanels () {
      return [
        { name: 'notifications', component: NotificationsListPanel },
        { name: 'taches', component: TasksListPanel },
        { name: 'favoris', component: FavoritesListPanel }
      ]
    }
  },

  watch: {
    '$route.path': {
      handler (newVal, oldVal) {
        this.leftDrawerOpen = false
        this.rightDrawerOpen = false
      }
    }
  },

  mounted () {
    this.$store.dispatch('config/fetchCounts')
    this.$store.dispatch('favorites/fetchFavorites')
  },

  methods: {
    showRightDrawerTab (indicator) {
      // Force the reload of the call of on mounted
      this.rightDrawerActiveTab = null
      this.$nextTick(() => { this.rightDrawerActiveTab = indicator })

      this.rightDrawerOpen = true
    }
  }
}
</script>

<style>
</style>
