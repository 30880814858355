<template>
  <q-btn-dropdown icon="person" color="primary" flat size="sm" auto-close class="q-mx-sm">
    <q-list v-if="user">
      <q-item clickable :to="{ name: 'user-show', params: { id: user.id } }">
        <q-item-section avatar>
          <q-avatar rounded size="32px" color="users" text-color="white">
            {{user.display_name.charAt(0).toUpperCase()}}
          </q-avatar>
        </q-item-section>

        <q-item-section>
          <q-item-label>{{user.display_name}}</q-item-label>
          <q-item-label caption>
            {{$t(`users.fields.role.options.${user.role}`)}}
          </q-item-label>
        </q-item-section>
      </q-item>

      <q-separator />

      <q-item clickable @click.native="logout">
        <q-item-section side>
          <q-icon name="lock" />
        </q-item-section>

        <q-item-section>
          <q-item-label>{{$t('auth.logout')}}</q-item-label>
        </q-item-section>

      </q-item>
    </q-list>
  </q-btn-dropdown>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      user: 'auth/getCurrentUser'
    })
  },

  mounted () {
    this.$store.dispatch('auth/fetchUser')
  },

  methods: {
    logout () {
      this.$store.dispatch('auth/logout')
        .then(() => {
          this.$q.notify({
            message: this.$t('auth.logout_success'),
            color: 'warning'
          })
          this.$router.push('/login')
        })
        .catch(error => {
          this.$q.notify({
            message: this.$t(error.message),
            color: 'negative'
          })
        })
    }
  }
}
</script>

<style>
</style>
