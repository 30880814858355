<template>
  <q-list dense class="text-weight-bold text-grey-7 q-pa-xs">
    <q-item :to="item.route" active-class="text-agora-light-2" :style="indent">{{$t(item.label)}}</q-item>
    <DrawerMenu v-for="child in item.children" v-bind:key="child.label"
      :item="child" :depth="depth + 1" />
  </q-list>
</template>

<script>
export default {
  name: 'DrawerMenu',
  props: { 'item': { type: Object, required: true }, 'depth': { type: Number, default: 0 } },
  computed: {
    indent () {
      return { 'padding-left': `${this.depth * 0.5}rem` }
    }
  }
}
</script>

<style lang="stylus" scoped>
</style>
