<template>
  <q-btn-group flat class="q-mx-sm">
    <q-btn v-for="{ name, label, icon } in indicators" v-bind:key="`indicator-${name}`"
      dense text-color="grey-7" @click="$emit('click', name)">
      <q-icon :name="icon" size="1rem" />
      <q-badge color="white" text-color="primary"><strong>{{counts ? counts[name] : 0}}</strong></q-badge>
    </q-btn>
  </q-btn-group>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'indicators',

  computed: {
    ...mapGetters({
      counts: 'config/getCounts'
    })
  },

  data () {
    return {
      search: null,
      indicators: [
        {
          name: 'notifications',
          label: 'notifications',
          icon: 'notifications'
        },
        {
          name: 'taches',
          label: 'tâches',
          icon: 'build'
        },
        {
          name: 'favoris',
          label: 'favoris',
          icon: 'star'
        }
      ]
    }
  }
}
</script>

<style lang="stylus" scoped>
</style>
