<template>
  <div class="">
    <q-list class="q-ma-md" v-if="tasks" separator>
      <q-item v-for="task in tasks" :key="task.id">
        <q-item-section avatar>
          <q-avatar size="3rem" text-color="tasks" icon="build" />
        </q-item-section>

        <q-item-section>
          <div class="row justify-between items-center">
            <div>
              <q-item-label caption>{{$formatDate(task.date_cible)}}</q-item-label>
              <q-item-label class="text-default">
                <router-link :to="{ name: `task-show`, params: { id: task.id } }">
                  <strong>{{task.label}}</strong>
                </router-link>
              </q-item-label>
            </div>
            <q-icon class="q-px-lg" size="1.5rem" color="grey-6" :name="$getIcon('statut', task.statut)" />
          </div>
        </q-item-section>
<!--
        <q-separator vertical class="q-my-sm"/>

        <q-item-section side top class="justify-center">
          <q-btn flat dense size="sm" icon="delete_outline" @click.prevent="deleteTask(task.id)" />
        </q-item-section> -->
      </q-item>
    </q-list>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'TasksListPanel',
  computed: {
    ...mapGetters({
      tasks: 'tasks/getTasks'
    })
  },

  watch: {
    activeFilter (newVal, oldVal) {
      this.fetchTasks()
    }
  },

  mounted () {
    this.fetchTasks()
  },

  methods: {
    fetchTasks () {
      const params = { rowsPerPage: 0, statut: 'EN_COURS' }
      this.$store.dispatch('tasks/fetchTasks', params)
    },

    deleteTask (id) {
      this.$confirm(this.$t('tasks.delete_task_confirm'), this.$t('form.delete')).onOk(() => {
        this.$store.dispatch('tasks/deleteTask', id).then(() => {
          this.fetchTasks()
        }).then(() => { this.$store.dispatch('config/fetchCounts') })
          .catch(this.$notifyError)
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
</style>
