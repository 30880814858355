<template>
  <div class="bg-white q-ma-md" v-if="favorites">
    <div v-if="Object.keys(favorites).length">
      <div v-for="favType in favsList" :key="favType" class="q-pa-none">
        <q-list v-if="favorites[favType]" class="q-py-sm">
          <q-item-section>
            <q-item-label header>{{$t(`${$pluralize(favType)}.${$pluralize(favType)}`).toUpperCase()}}</q-item-label>
          </q-item-section>

          <q-item v-for="{type, id, label, caption, ref} in favorites[favType]" :key="id">
            <q-item-section avatar>
              <q-avatar size="1.5rem" :color="colors[type]" text-color="white" />
            </q-item-section>

            <q-item-section>
              <q-item-label class="text-default">
                <router-link :to="{ name: `${ref.type}-show`, params: { id: ref.id } }">
                  <strong>{{label}}</strong>
                </router-link>
              </q-item-label>
              <q-item-label caption>{{caption}}</q-item-label>
            </q-item-section>

            <q-separator vertical class="q-my-sm"/>

            <q-item-section side top class="justify-center">
              <q-btn flat dense size="sm" icon="delete_outline" @click.prevent="deleteFavorite(type, id)" />
            </q-item-section>
          </q-item>

          <q-separator spaced />
        </q-list>
      </div>
    </div>

    <div v-else v-html="$t('favorites.no_favorites')" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'FavoritesListPanel',
  computed: {
    ...mapGetters({
      favorites: 'favorites/getFavorites'
    })
  },

  data () {
    return {
      favsList: ['entity', 'agent', 'wallet', 'cible', 'sousCible', 'task', 'evenement'],
      colors: {
        'entity': 'reseau',
        'agent': 'reseau',
        'wallet': 'reseau',
        'cible': 'per',
        'sousCible': 'per',
        'task': 'tasks',
        'evenement': 'reseau'
      }
    }
  },

  methods: {
    deleteFavorite (model_type, model_id) {
      model_type = model_type === 'cible' ? 'groupeCible' : model_type
      model_type = model_type === 'sousCible' ? 'cible' : model_type

      this.$store.dispatch('favorites/deleteFavorite', { model_type, model_id })
        .then(() => { this.$q.notify({ message: this.$t(`favorites.delete_success`), color: 'positive' }) })
        .then(() => { this.$store.dispatch('config/fetchCounts') })
    }
  }
}
</script>

<style lang="stylus" scoped>
</style>
