<template>
  <q-select v-model="searchQuery" :options="searchResults" label="Recherche..."
    use-input dense options-dense hide-dropdown-icon
    @filter="filterFn"
    @input="onResultSelect"
    transition-show="jump-up" transition-hide="jump-down">
    <template slot="prepend">
      <q-icon name="search" size="16px" />
    </template>

    <template v-slot:option="scope">
      <div v-if="!scope.opt.action">
        <q-separator />
        <q-item-label header>{{$t(`${$pluralize(scope.opt.label)}.${scope.opt.label}`)}}</q-item-label>
      </div>
      <div v-else>
        <q-item v-bind="scope.itemProps" v-on="scope.itemEvents" clickable>
          <q-item-section>
            <q-item-label>{{scope.opt.label}}</q-item-label>
          </q-item-section>
        </q-item>
      </div>
    </template>

    <template slot="no-option">
      <q-item>
        <q-item-section class="text-grey">
          Aucun résultats
        </q-item-section>
      </q-item>
    </template>
  </q-select>
</template>

<script>
export default {
  data () {
    return {
      searchResults: [],
      searchQuery: null
    }
  },

  methods: {
    filterFn (val, update, abort) {
      if (val.length < 2) {
        abort()
        return
      }

      this.$axios.post('/api/search', { model_type: this.modelType, req: val.toLowerCase() })
        .then(response => response.data)
        .then(results => {
          update(() => {
            let options = []

            results.forEach(result => {
              let label = Object.keys(result).pop()
              let value = result[label], id

              if (label === 'groupeCible') label = 'cible'
              else if (label === 'cible') label = 'sous-cible'
              else if (label === 'tache') label = 'task'

              if (value.length) {
                options.push({ label })

                let model = label

                value.forEach(entry => {
                  if (!entry.label) return

                  if (label === 'entity') id = entry.value
                  else if (label === 'wallet') id = entry['entity_id']
                  else if (label === 'agent') {
                    if (!entry['entities'][0]) return
                    id = entry['entities'][0]['id']
                  } else if (label === 'cible') id = entry['id']
                  else if (label === 'task') id = entry['value']

                  if (label === 'wallet' || label === 'agent') model = 'entity'

                  options.push({
                    label: entry.label,
                    action: { name: `${model}-show`, params: { id } }
                  })
                })
              }
            })

            this.searchResults = options
          })
        }).catch()
        .finally()
    },

    onResultSelect ({ label, action }) {
      if (action) {
        this.$router.push(action)
        this.searchQuery = null
      }
      // this.$emit('input', { type: this.modelType, value: val })
      // this.searchQuery = val
    },

    reset () {
      this.modelType = null
      this.searchResults = []
      this.searchQuery = null
    }
  }
}
</script>

<style lang="stylus" scoped>
</style>
