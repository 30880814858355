<template>
  <q-drawer :value="value" @input="val => $emit('input', val)" :width="400"
    side="right" overlay elevated content-class="bg-white" behavior="mobile" no-swipe-close>
    <slot name="tabs" />

    <q-separator />

    <slot name="tab-panels" />
    <!-- <q-scroll-area class="fit">
    </q-scroll-area> -->

  </q-drawer>
</template>

<script>
export default {
  name: 'RightDrawer',
  props: {
    'value': { type: Boolean, required: true }
  }
}
</script>

<style lang="stylus" scoped>
</style>
